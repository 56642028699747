import axios from '@axios'

export default {
  namespaced: true,
  state: {
    load: false,

  },
  getters: {
    getLoad(state) {
      return state.load
    },
  },
  mutations: {
    alteraLoading(state, data) {
      state.load = data
    },

  },
  actions: {
    fetchDados({ commit }, queryParams) {
      commit('alteraLoading', true)
      return new Promise((resolve, reject) => {
        axios
          .get('/checklists', { params: queryParams })
          .then(response => {
            resolve(response.data)
            commit('alteraLoading', false)
          })
          .catch(error => console.log(error))
      })
    },

    show(ctx, item) {
      return new Promise((resolve, reject) => {
        axios
          .get(`checklists/${item.id.uuid}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    store(ctx, itemData) {
      const formData = new FormData()

      formData.append('descricao', itemData.nome)

      return new Promise((resolve, reject) => {
        axios
          .post('/checklists', formData)
          .then(response => resolve((response => {

          })))
          .catch(error => reject(error))
      })
    },

    update(ctx, itemData) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()

        formData.append('descricao', itemData.descricao)

        formData.append('_method', 'PUT')

        axios.post(`checklists/${itemData.uuid}`, formData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    deleteItem(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/checklists/${id}`)
          .then(response => resolve())
          .catch(error => reject(error))
      })
    },
  },
}
